import CTracker from "components/CTracker";
import { TrackerContextProvider } from "components/CTracker/useTrackerContext";

import ReapplicationModal from "./ReapplicationModal";

import Loan from "typedef/Loan";

interface AdminBorrowerTrackerProps {
  loan?: Loan;
  isBorrower?: boolean;
}

const AdminBorrowerTracker = ({
  loan,
  isBorrower,
}: AdminBorrowerTrackerProps) => {
  const isLoanArchived = loan?.borrowerFlags?.archiveType;

  if (isLoanArchived) {
    return <ReapplicationModal loan={loan} />;
  }

  return (
    <TrackerContextProvider>
      <CTracker isBorrower={isBorrower ?? true} />
    </TrackerContextProvider>
  );
};

export default AdminBorrowerTracker;
