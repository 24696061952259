import { Box, Button, useTheme, Typography, MenuItem } from "@mui/material";
import CTextField from "components/CTextField";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { RegisterFormInputs } from "./useRegisterForm";
import CNumberField from "components/CNumberField";

type FormCoborrowerProps = {
  control: Control<RegisterFormInputs, unknown>;
  disabledField: boolean;
  disabledFieldMarital: boolean;
  errors: FieldErrors<RegisterFormInputs>;
  deleteCoborrower: () => void;
};

type DividerProps = {
  color: string;
};

const Divider: React.FC<DividerProps> = ({ color }) => (
  <div
    style={{
      borderBottom: `1px solid ${color}`,
      margin: "30px 0",
    }}
  />
);

const FormCoborrower: React.FC<FormCoborrowerProps> = ({
  control,
  disabledField,
  disabledFieldMarital,
  errors,
  deleteCoborrower,
}) => {
  const theme = useTheme();

  return (
    <>
      <Box display={"flex"} justifyContent="space-between" alignItems="center">
        <Typography
          mt={4}
          mb={5}
          variant="h1"
          lineHeight={1.2}
          color={theme.palette.text.primary}
          letterSpacing={-0.8}
          px={{ xs: 2, md: 0 }}
          fontSize={{ xs: "1.5rem", md: "2rem" }}
        >
          Secondary Borrower
        </Typography>
        <Button
          variant="text"
          sx={{
            textTransform: "none",
          }}
          onClick={deleteCoborrower}
        >
          Delete Co-Borrower
        </Button>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"flex-start"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
          <Controller
            control={control}
            name="legalFirstNameCoborrower"
            render={({ field }) => (
              <CTextField
                label="Legal First Name"
                placeholder="John"
                fullWidth
                autoComplete="off"
                disabled={disabledField}
                sx={{ mb: 3 }}
                {...field}
                error={errors?.legalFirstNameCoborrower?.message}
              />
            )}
          />
        </Box>
        <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
          <Controller
            control={control}
            name="legalLastNameCoborrower"
            render={({ field }) => (
              <CTextField
                label="Legal Last Name"
                placeholder="Doe"
                fullWidth
                autoComplete="off"
                disabled={disabledField}
                sx={{ mb: 3 }}
                {...field}
                error={errors?.legalLastNameCoborrower?.message}
              />
            )}
          />
        </Box>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"flex-start"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
          <Controller
            control={control}
            name="legalMiddleNameCoborrower"
            render={({ field }) => (
              <CTextField
                label="Legal Middle Name (Optional)"
                fullWidth
                placeholder="Paul"
                autoComplete="off"
                sx={{ mb: 3 }}
                {...field}
                error={errors?.legalMiddleNameCoborrower?.message}
              />
            )}
          />
        </Box>
        <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
          <Controller
            control={control}
            name="legalSuffixCoborrower"
            render={({ field }) => (
              <CTextField
                label="Suffix (Optional)"
                placeholder="JR, SR"
                fullWidth
                autoComplete="off"
                disabled={disabledField}
                sx={{ mb: 3 }}
                {...field}
                error={errors?.legalSuffixCoborrower?.message}
              />
            )}
          />
        </Box>
      </Box>
      <Controller
        control={control}
        name="maritalStatusCoborrower"
        render={({ field }) => (
          <CTextField
            select
            fullWidth
            label="Marital Status"
            disabled={disabledFieldMarital}
            sx={{ mb: 3 }}
            {...field}
            error={errors?.maritalStatusCoborrower?.message}
          >
            <MenuItem value="Unmarried">Unmarried</MenuItem>
            <MenuItem value="Married">Married</MenuItem>
            <MenuItem value="Separated">Separated</MenuItem>
          </CTextField>
        )}
      />
      <Controller
        control={control}
        name="phoneCoborrower"
        render={({ field }) => (
          <CNumberField
            fullWidth
            autoComplete="off"
            label="Cell Phone"
            disabled={disabledField}
            placeholder="+1(123)-123-1234"
            format={
              process.env.REACT_APP_ENV === "prod"
                ? "+1###############"
                : "+###############"
            }
            sx={{ mb: 3 }}
            allowEmptyFormatting
            {...field}
            error={errors?.phoneCoborrower?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="confirmEmailAddressCoborrower"
        render={({ field }) => (
          <CTextField
            label="Email Address"
            disabled={disabledField}
            placeholder="Type in here"
            fullWidth
            autoComplete="off"
            sx={{ mb: 3 }}
            {...field}
            error={errors?.confirmEmailAddressCoborrower?.message}
          />
        )}
      />
      <Divider color={theme.palette.text.disabled} />
    </>
  );
};
export default FormCoborrower;
