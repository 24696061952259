import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTracker from "components/CTracker/useTrackerContext";
import { ClickAwayListener } from "@mui/material";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";

const FinalOfferVerification: React.FC = () => {
  const { loan } = useTracker();
  const navigate = useNavigate();
  const { user } = useUserCustomerContext();
  const [showDifferentOfferWarning, setShowDifferentOfferWarning] =
    useState(false);
  const isBorrower = user && user.id === loan?.borrowerId;
  const isBroker = loan?.brokerId && loan?.brokerSk;
  const brokerDisclosures = isBroker
    ? loan?.borrowerSteps?.brokerDisclosuresVerification?.status === "success"
    : true;

  useEffect(() => {
    if (
      (isBorrower &&
        loan?.finalOffer &&
        !loan?.borrowerFlags?.finalOfferAccepted &&
        brokerDisclosures &&
        loan?.borrowerSteps?.disclosuresVerification?.status === "success" &&
        (loan?.coborrowerId ||
          (loan?.property?.NPE &&
            (loan?.borrowerFlags?.offeredAddingNPEAsCoborrower ||
              loan?.borrowerFlags?.offeredCoborrowerInvite)) ||
          !loan?.property?.NPE) &&
        loan?.borrowerSteps?.incomeVerification?.status === "success" &&
        loan?.borrowerSteps?.titleVerification?.status === "success" &&
        loan?.borrowerSteps?.disclosuresVerification?.status === "success" &&
        loan?.borrowerSteps?.creditVerification?.status === "success" &&
        (loan.borrowerSteps?.homeValuationVerification?.status === "success" ||
          (loan?.borrowerSteps?.homeValuationVerification?.status ===
            "analyzing" &&
            loan?.borrowerSteps?.homeValuationVerification?.reportType ===
              "BPO" &&
            loan?.borrowerSteps?.homeValuationVerification?.orderId)) &&
        (loan?.initialOffer?.useProceeds !== "Debt Consolidation" ||
          loan?.borrowerFlags?.debtsCofirmed)) ||
      loan?.borrowerFlags?.reAcceptedFinalOffer === false
    ) {
      navigate("/final-offer");
    }
  }, [
    loan?.finalOffer,
    loan?.borrowerFlags?.finalOfferAccepted,
    isBorrower,
    loan?.borrowerSteps?.creditVerification?.status,
    loan?.borrowerSteps?.incomeVerification?.status,
    loan?.borrowerSteps?.titleVerification?.status,
    loan?.borrowerSteps?.homeValuationVerification?.status,
    loan?.borrowerSteps?.homeValuationVerification?.reportType,
    loan?.borrowerSteps?.homeValuationVerification?.orderId,
    navigate,
    loan?.initialOffer?.useProceeds,
    loan?.borrowerFlags?.debtsCofirmed,
    loan?.borrowerFlags?.reAcceptedFinalOffer,
    loan?.borrowerFlags?.offeredAddingNPEAsCoborrower,
    loan?.borrowerFlags?.offeredCoborrowerInvite,
    loan?.property?.NPE,
    loan?.coborrowerId,
    brokerDisclosures,
    loan?.borrowerSteps?.disclosuresVerification?.status,
  ]);

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <ClickAwayListener onClickAway={() => {}}>
        <CDialog
          fullWidth
          maxWidth="xs"
          icon="warning"
          scroll={"paper"}
          sx={{ padding: "2rem" }}
          title="Your offer has changed."
          open={showDifferentOfferWarning}
          onClose={() => setShowDifferentOfferWarning(false)}
          disableEscapeKeyDown
        >
          <CButton
            fullWidth
            onClick={() => navigate("/final-offer", { replace: true })}
            variant="contained"
            name="finalOfferDialog-viewNewOffer"
          >
            View new offer
          </CButton>
        </CDialog>
      </ClickAwayListener>
    </div>
  );
};

export default FinalOfferVerification;
