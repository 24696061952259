export const LSisborrower = "isBorrowerNFTYDoor";
export const LStoken = "tokenNFTYDoor";
export const LSAdminToken = "adminTokenNFTYDoor";
export const LStokenExp = "tokenNFTYDoorExp";
export const LSAdminTokenExp = "adminTokenNFTYDoorExp";
export const passwordMsgHelper =
  "Minimum length is 9 characters with at least 1 number, 1 special character, 1 uppercase letter and 1 lowercase letter.";
export const regexPassword =
  //eslint-disable-next-line
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;
