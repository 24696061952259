import { LSAdminToken, LSAdminTokenExp } from "CONST";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserType } from "typedef/ApiUserResponses";
import Loan from "typedef/Loan";

export const useAdminImpersonate = (
  loan?: Loan,
  isBorrower?: boolean,
  userAdmin?: UserType,
  loadingLoan?: boolean,
) => {
  const params = useParams();
  const loanId = params?.loanId;
  const adminToken = localStorage.getItem(LSAdminToken);
  const adminTokenExp = localStorage.getItem(LSAdminTokenExp);

  const typeOfCreditLine =
    isBorrower &&
    (!loan?.loanType ||
      loan?.borrowerFlags?.userHasLoanInOtherPrivateLabel ||
      (loan.loanType && loan.borrowerFlags?.completedByBorrower));

  const isBroker = loan?.brokerId && loan?.brokerSk;
  const brokerDisclosures = isBroker
    ? loan?.borrowerSteps?.brokerDisclosuresVerification?.status === "success"
    : true;

  const finalOffer =
    (isBorrower &&
      loan?.finalOffer &&
      !loan?.borrowerFlags?.finalOfferAccepted &&
      brokerDisclosures &&
      loan?.borrowerSteps?.disclosuresVerification?.status === "success" &&
      (loan?.coborrowerId ||
        (loan?.property?.NPE &&
          (loan?.borrowerFlags?.offeredAddingNPEAsCoborrower ||
            loan?.borrowerFlags?.offeredCoborrowerInvite)) ||
        !loan?.property?.NPE) &&
      loan?.borrowerSteps?.incomeVerification?.status === "success" &&
      loan?.borrowerSteps?.titleVerification?.status === "success" &&
      loan?.borrowerSteps?.creditVerification?.status === "success" &&
      (loan.borrowerSteps?.homeValuationVerification?.status === "success" ||
        (loan?.borrowerSteps?.homeValuationVerification?.status ===
          "analyzing" &&
          loan?.borrowerSteps?.homeValuationVerification?.reportType ===
            "BPO" &&
          loan?.borrowerSteps?.homeValuationVerification?.orderId)) &&
      (loan?.initialOffer?.useProceeds !== "Debt Consolidation" ||
        loan?.borrowerFlags?.debtsCofirmed)) ||
    loan?.borrowerFlags?.reAcceptedFinalOffer === false;

  const borrowerTracker =
    isBorrower && loan?.borrowerSteps !== undefined && loan?.loanType;

  const coborrowerTracker = loan?.coborrowerSteps !== undefined;

  const [stateView, setStateView] = useState("");

  useEffect(() => {
    if (!loadingLoan) {
      if (userAdmin?.email && userAdmin?.id) {
        if (!loan?.id && !loan?.borrowerId) {
          setStateView("error");
          setMessageError(`Loan: #${loanId} not found.`);
        } else if (isBorrower && loan?.borrowerId?.includes("temp_borrower_")) {
          setStateView("error");
          setMessageError("The Borrower has not register yet.");
        } else if (
          !isBorrower &&
          loan?.coborrowerId?.includes("temp_coborrower_")
        ) {
          setStateView("error");
          setMessageError("The Co-Borrower has not register yet.");
        } else if (borrowerTracker || coborrowerTracker) {
          setStateView("tracker");
        } else if (typeOfCreditLine) {
          setStateView("typeOfCreditLine");
        }

        if (finalOffer) {
          setStateView("finalOffer");
        }

        if (!("hasBorrowerImpersonationCapability" in userAdmin)) {
          setStateView("error");
          setMessageError(
            `The email ${userAdmin?.email} does not have the necessary permissions to impersonate a borrower.`,
          );
        }
      }
    }
    if (!adminToken && !adminTokenExp) {
      setStateView("login");
    }
  }, [
    loadingLoan,
    isBorrower,
    coborrowerTracker,
    borrowerTracker,
    userAdmin,
    finalOffer,
    typeOfCreditLine,
    loan,
    adminToken,
    adminTokenExp,
    loanId,
  ]);
  const [messageForAdmin, setMessageForAdmin] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);

  const onClick = () => {
    setMessageForAdmin("The admin is not allowed to interact with the page");
    setTimeout(() => {
      setMessageForAdmin(null);
    }, 3000);
  };
  return { stateView, messageError, messageForAdmin, onClick };
};
