import Address from "typedef/Address";
import { useParams } from "react-router-dom";
import { RegisterFormInputs } from "./useRegisterForm";
import { useInvite } from "context/Invite/useInviteContext";
import parseMoney from "utils/parseMoney";

type RegisterFormInputsWithIsMobile = RegisterFormInputs & {
  isB1Mobile?: boolean;
  isB2Mobile?: boolean;
};

const parseIncomes = (income: string) => {
  if (!income) {
    return 0;
  }
  const numericValue = income.replace(/[$,\s]/g, "");
  const numberValue = parseFloat(numericValue);
  return numberValue;
};
const sumIncomes = (inputs: (string | undefined)[]) =>
  inputs
    .map((value) => (value ? parseMoney(value) : 0))
    .reduce((acc, curr) => acc + curr, 0);
const useParseToSubmit = () => {
  const invite = useInvite();
  const params = useParams();
  const sourceId = params["sourceId"];

  return (data: RegisterFormInputsWithIsMobile) => {
    const totalAnnualIncomeBorrower = sumIncomes([
      data?.salaryIncome,
      data?.selfEmploymentIncome,
      data?.socialIncome,
      data?.otherIncome,
    ]);
    const totalAnnualIncomeCoborrower = sumIncomes([
      data?.salaryIncomeCoborrower,
      data?.selfEmploymentIncomeCoborrower,
      data?.socialIncomeCoborrower,
      data?.otherIncomeCoborrower,
    ]);
    const estimatedHomeValue = data.estimatedHomeValue;
    const requestedLoanAmount = data.requestedLoanAmount;
    const currentLoanBalance = data.currentLoanBalance;
    const phoneBorrower = data.phoneBorrower.replace(/ /g, "");
    const creditScoreRange = data.creditScoreRange;
    const { street_line, ...restOfAddress } = data.propertyAddress;
    const propertyAddress = {
      street: street_line,
      ...restOfAddress,
    };
    let borrowerAddress: (Address & { street?: string }) | undefined;
    if (data.borrowerAddress) {
      const { ...propsBorrowerAddress } = data.borrowerAddress;

      borrowerAddress = {
        street: propsBorrowerAddress?.street_line,
        ...propsBorrowerAddress,
      };
    }
    const addCoborrower = data.addCoborrower === "Yes";
    const employmentType = data?.salaryIncome
      ? "Full Time Employed/ W2"
      : "Self Employed";
    const employmentTypeCoborrower = data?.salaryIncomeCoborrower
      ? "Full Time Employed/ W2"
      : "Self Employed";

    return {
      PLMasterLoanOfficer: data?.PLMasterLoanOfficer,
      pricingEngineId: data?.pricingEngineId,
      propertyAddress,
      borrowerAddress,
      confirmEmailAddressBorrower: data.confirmEmailAddressBorrower?.trim(),
      hasAcceptTerms: true,
      agreeNotifications: true,
      legalLastNameBorrower: data.legalLastNameBorrower?.trim(),
      phoneBorrower,
      legalFirstNameBorrower: data.legalFirstNameBorrower?.trim(),
      useOfProceeds: { label: data.useOfProceeds, value: data.useOfProceeds },
      occupancyType: { label: data.occupancyType, value: data.occupancyType },
      estimatedHomeValue: estimatedHomeValue,
      employmentTypeBorrower: {
        label: employmentType,
        value: employmentType,
      },
      maritalStatusBorrower: {
        label: data.maritalStatusBorrower,
        value: data.maritalStatusBorrower,
      },
      totalAnnualIncomeBorrower: totalAnnualIncomeBorrower.toString(),
      requestedLoanAmount: requestedLoanAmount,
      currentLoanBalance: currentLoanBalance,
      creditScoreRange: { label: creditScoreRange, value: creditScoreRange },
      inviteCode: invite?.code,
      sourceId: sourceId,
      completedByBorrower: true,
      legalSuffixBorrower:
        data.legalSuffixBorrower === ""
          ? undefined
          : data.legalSuffixBorrower?.trim(),
      employerNameBorrower:
        data.employerNameBorrower === ""
          ? undefined
          : data.employerNameBorrower?.trim(),
      startDateBorrower:
        data.startDateBorrower === ""
          ? undefined
          : data.startDateBorrower?.trim(),
      loanOfficerId: invite?.loanOfficerId,
      brokerId: invite?.broker?.id,
      brokerSk: invite?.broker?.sk,
      password: data.password,
      customAddress:
        data.propertyAddress?.street_line === "My address is not listed"
          ? {
              city: data?.manualAddress.city?.trim(),
              secondary: data?.manualAddress?.secondary?.trim(),
              state: data?.manualAddress?.state?.toLocaleUpperCase()?.trim(),
              street_name: data?.manualAddress?.street_line?.trim(),
              street_number: data?.manualAddress?.street_number?.trim(),
              zipcode: data?.manualAddress?.zipcode?.trim(),
              street_suffix: data?.manualAddress?.street_suffix?.trim(),
            }
          : undefined,
      manualPropertyAddress:
        data.propertyAddress?.street_line === "My address is not listed",
      manualBorrowerAddress:
        data.borrowerAddress?.street_line === "My address is not listed",
      customBorrowerAddress:
        data.borrowerAddress?.street_line === "My address is not listed"
          ? {
              city: data?.manualBorrowerAddress?.city?.trim(),
              secondary: data?.manualBorrowerAddress?.secondary?.trim(),
              state: data?.manualBorrowerAddress?.state
                ?.toLocaleUpperCase()
                ?.trim(),
              street_name: data?.manualBorrowerAddress?.street_line?.trim(),
              street_number: data?.manualBorrowerAddress?.street_number?.trim(),
              zipcode: data?.manualBorrowerAddress?.zipcode?.trim(),
              street_suffix: data?.manualBorrowerAddress?.street_suffix?.trim(),
            }
          : undefined,
      legalMiddleNameBorrower:
        data.legalMiddleNameBorrower === ""
          ? undefined
          : data?.legalMiddleNameBorrower?.trim(),
      incomeSourcesBorrower: {
        salaryIncome: parseIncomes(data.salaryIncome ?? ""),
        otherIncome: parseIncomes(data.otherIncome ?? ""),
        selfEmploymentIncome: parseIncomes(data.selfEmploymentIncome ?? ""),
        socialIncome: parseIncomes(data.socialIncome ?? ""),
      },
      ...(addCoborrower && {
        legalFirstNameCoborrower: data?.legalFirstNameCoborrower?.trim(),
        legalLastNameCoborrower: data?.legalLastNameCoborrower?.trim(),
        legalMiddleNameCoborrower: data?.legalMiddleNameCoborrower?.trim(),
        legalSuffixCoborrower: data?.legalSuffixCoborrower?.trim(),
        maritalStatusCoborrower: {
          label: data.maritalStatusCoborrower,
          value: data.maritalStatusCoborrower,
        },
        confirmEmailAddressCoborrower:
          data?.confirmEmailAddressCoborrower?.trim(),
        phoneCoborrower: data?.phoneCoborrower?.replace(/ /g, ""),
        incomeSourcesCoborrower: {
          salaryIncome: parseIncomes(data.salaryIncomeCoborrower ?? ""),
          otherIncome: parseIncomes(data.otherIncomeCoborrower ?? ""),
          selfEmploymentIncome: parseIncomes(
            data.selfEmploymentIncomeCoborrower ?? "",
          ),
          socialIncome: parseIncomes(data.socialIncomeCoborrower ?? ""),
        },
        totalAnnualIncomeCoborrower: totalAnnualIncomeCoborrower.toString(),
        employmentTypeCoborrower: {
          label: employmentTypeCoborrower,
          value: employmentTypeCoborrower,
        },
        employerNameCoborrower: data.employerNameCoborrower?.trim(),
        startDateCoborrower: data.startDateCoborrower?.trim(),
      }),
      addCoborrower: data.addCoborrower,
      isB1Mobile: data?.isB1Mobile,
      isB2Mobile: data?.isB2Mobile,
    };
  };
};

export default useParseToSubmit;
