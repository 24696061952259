import Loan from "typedef/Loan";
import useLoan from "./useLoan";
import CLoader from "components/CLoader";
import { PropertyType } from "typedef/Property";
import React, { ReactNode, useContext, useMemo, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useParams } from "react-router-dom";

export type TrackerContextType = {
  loan?: Loan;
  property?: PropertyType;
  type?: "borrower" | "coborrower";
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => typeof value;
  coborrowerInviteIsOpen: boolean;
  setCoborrowerInviteIsOpen: (value: boolean) => typeof value;
};

export const TrackerContext = React.createContext<TrackerContextType>({
  isModalOpen: false,
  setIsModalOpen: (value: boolean) => value,
  coborrowerInviteIsOpen: false,
  setCoborrowerInviteIsOpen: (value: boolean) => value,
});

const useTracker = () => {
  return useContext(TrackerContext);
};

export const TrackerContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { user, userAdmin } = useUser();
  const isAdmin = userAdmin?.email && userAdmin?.id;
  const params = useParams();
  const isCoborrower = params?.borrowerType === "coborrower";

  const loan = useLoan();
  const property = useMemo(() => loan?.property, [loan?.property]);
  const typeUserAdmin = isCoborrower ? "coborrower" : "borrower";

  const typeUser = loan?.borrowerId === user?.id ? "borrower" : "coborrower";
  const typeBorrower = isAdmin ? typeUserAdmin : typeUser;

  const type = useMemo(() => typeBorrower, [typeBorrower]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coborrowerInviteIsOpen, setCoborrowerInviteIsOpen] = useState(false);

  const inTrackerScreen = useMemo(
    () =>
      window.location.pathname === "/borrower-tracker" ||
      window.location.pathname === "/coborrower-tracker",
    [],
  );

  const value = useMemo(
    () =>
      ({
        loan,
        property,
        type,
        isModalOpen,
        setIsModalOpen,
        coborrowerInviteIsOpen,
        setCoborrowerInviteIsOpen,
      }) as TrackerContextType,
    [loan, property, type, isModalOpen, coborrowerInviteIsOpen],
  );
  return (
    <TrackerContext.Provider value={value}>
      {inTrackerScreen && <CLoader loading={loan ? false : true} />}
      {children}
    </TrackerContext.Provider>
  );
};

export default useTracker;
