import API from "utils/API";
import Loan from "typedef/Loan";
import HELOCOffer from "./HELOCOffer";
import HELOANOffer from "./HELOANOffer";
import CLoader from "components/CLoader";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface FinalOfferProps {
  isAdmin?: boolean;
}

const FinalOffer = ({ isAdmin }: FinalOfferProps) => {
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    (async () => {
      setLoading(true);
      const loanResponse =
        isAdmin && currentAdminLoanId
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanId}`,
            )
          : await API.get<Loan>(`/get/my-loan`);

      if ("error" in loanResponse) {
        return setLoading(false);
      } else {
        setLoan(loanResponse.data);
        setLoading(false);
      }
    })();
  }, [isAdmin, currentAdminLoanId]);

  return (
    <Fragment>
      <CLoader loading={loading} />
      {loan && loan.loanType === "HELOAN" && <HELOANOffer />}
      {loan && loan.loanType === "HELOC" && <HELOCOffer />}
    </Fragment>
  );
};

export default FinalOffer;
