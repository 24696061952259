import React, {
  useState,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
} from "react";
import API from "utils/API";
import Loan from "typedef/Loan";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import { LoginType } from "typedef/login";
import {
  LSisborrower,
  LStoken,
  LStokenExp,
  LSAdminToken,
  LSAdminTokenExp,
} from "CONST";
import { useLocation, useNavigate } from "react-router-dom";
import useFHConnectUser from "./useFHConnectUser";
import { UserType } from "typedef/ApiUserResponses";
import { errorMessages } from "utils/errorMessages";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";

export type UserContextType = {
  loan?: Loan;
  loadingLoan: boolean;
  logout(): void;
  user?: UserType;
  userAdmin?: UserType;
  loadingUserData: boolean;
  isGettingLoggedIn: boolean;
  login(email: string, password: string): Promise<void | string>;
  adminLogin(email: string, password: string): Promise<void | string>;
  setUser: React.Dispatch<React.SetStateAction<UserType | undefined>>;
  inviteError?: string;
  setCurrentAdminLoanId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  getAdminLoan(): Promise<void | string>;
  openedDrawer: boolean;
  setOpenedDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

const useUser = () => {
  return useContext(UserContext);
};

export const UserContext = React.createContext<UserContextType>({
  user: {},
  loan: undefined,
  loadingLoan: false,
  logout: () => undefined,
  loadingUserData: false,
  isGettingLoggedIn: false,
  setUser: (user) => user,
  login: async (_email, _password) => undefined,
  adminLogin: async (_email, _password) => undefined,
  userAdmin: {},
  setCurrentAdminLoanId: () => undefined,
  getAdminLoan: async () => undefined,
  openedDrawer: false,
  setOpenedDrawer: () => undefined,
});

/**
 * @author Giuliano Antonanzas
 */
export const UserCustomerContextProvider: React.FC<{
  children: ReactNode;
  loanId?: string;
}> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openedDrawer, setOpenedDrawer] = useState(false);

  const [currentAdminLoanId, setCurrentAdminLoanId] = useState<
    string | undefined
  >();
  const FHConnectUser = useFHConnectUser();
  const [loan, setLoan] = useState<Loan>();
  const [loadingLoan, setLoadingLoan] = useState<boolean>(false);
  const [loanId, setLoanId] = useState("");
  const token = localStorage.getItem(LStoken);
  const tokenExp = localStorage.getItem(LStokenExp);

  const adminToken = localStorage.getItem(LSAdminToken);
  const adminTokenExp = localStorage.getItem(LSAdminTokenExp);

  const [user, setUser] = useState<UserType>();
  const [userAdmin, setUserAdmin] = useState<UserType>();
  const [modalIsShown, setModalIsShown] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [isGettingLoggedIn, setIsGettingLoggedIn] = useState(false);
  const [inviteError, setInviteError] = useState("");
  const [modalInviteErrorShown, setModalInviteErrorIsShown] = useState(false);
  const { privateLabel } = usePrivateLabel();

  const logout = useCallback(() => {
    localStorage.removeItem(LStoken);
    localStorage.removeItem(LStokenExp);
    localStorage.removeItem(LSisborrower);
    setUser(undefined);
    navigate("/login", { replace: true });
  }, [navigate]);

  const adminLogout = useCallback(() => {
    localStorage.removeItem(LSAdminToken);
    localStorage.removeItem(LSAdminTokenExp);
    setUserAdmin(undefined);
    setLoadingUserData(false);
  }, []);

  const getAdminLoan = useCallback(async () => {
    if (!userAdmin?.id) return;
    setLoadingLoan(true);
    const result = await API.get<Loan>(
      `/admin-impersonate/get/loan/${currentAdminLoanId}`,
    );
    if ("error" in result) {
      return result.error;
    }
    setLoan(result?.data);
    setLoadingLoan(false);
  }, [userAdmin, currentAdminLoanId]);

  const login = useCallback(
    async (email: string, password: string) => {
      setIsGettingLoggedIn(true);
      const loginResponse = await API.post<LoginType>({
        url: "/login",
        data: {
          email: email.trim(),
          password,
        },
      });
      if ("error" in loginResponse) {
        setIsGettingLoggedIn(false);
        return loginResponse.error;
      }
      localStorage.setItem(
        LStokenExp,
        String(loginResponse.data.idToken.payload.exp * 1000),
      );
      localStorage.setItem(LStoken, loginResponse.data.idToken.jwtToken);
      setUser((prev) => ({
        ...prev,
        exp: loginResponse.data.idToken.payload.exp * 1000,
        token: loginResponse.data.idToken.jwtToken,
        id: loginResponse.data.accessToken.payload.sub,
      }));

      /** Check where the user should go to next */
      const getMyLoanResponse = await API.get<Loan>("/get/my-loan");
      if ("error" in getMyLoanResponse) {
        alert(getMyLoanResponse.error);
      } else {
        if ("body" in getMyLoanResponse.data) {
          setInviteError(
            JSON.parse(getMyLoanResponse?.data?.body as unknown as string),
          );
          setModalInviteErrorIsShown(true);
          logout();
        } else {
          const loan = getMyLoanResponse?.data;
          const isBorrower = user?.id === loan?.borrowerId;
          const stepName = isBorrower ? "borrowerSteps" : "coborrowerSteps";
          const currentStep = loan?.[stepName]?.creditVerification?.adminError;
          if (
            loan?.loanStatusCode === "ARCHIVED" &&
            currentStep !== errorMessages.creditVerification.adminError
          ) {
            logout();
          }
          setLoan(loan);
          setLoanId(loan.id);
          if (
            loan?.inviteCode &&
            loan?.borrowerFlags?.completedByBorrower &&
            loan?.submittedByLO
          ) {
            await API.post({
              url: "/delete-invite",
              data: loan,
            });
          }
          if (
            loan &&
            loan.borrowerFlags &&
            loan.borrowerFlags.archiveType &&
            loan.borrowerFlags.archiveType === "Paid Off"
          ) {
            setModalIsShown(true);
          }
          const typeOfCreditLine = loan.borrowerFlags?.initialOfferAccepted;

          const borrowerTracker =
            loan.borrowerSteps !== undefined &&
            loan?.loanType &&
            loan.borrowerFlags?.initialOfferAccepted &&
            !loan.borrowerFlags?.userHasLoanInOtherPrivateLabel;

          /** check if is borrower or coborrower */
          if (loan.borrowerId === loginResponse.data.accessToken.payload.sub) {
            /** if have borrower step, go to borrower tracker */
            if (borrowerTracker) {
              if (loan.borrowerSteps?.fundingVerification.status === "success")
                navigate("/home-monitor", { replace: true });
              else navigate("/borrower-tracker", { replace: true });
            } else {
              if (typeOfCreditLine) {
                navigate("/type-of-credit-line", { replace: true });
              } else {
                navigate("/initial-offer", { replace: true });
              }
            }
          } else {
            navigate("/coborrower-tracker", { replace: true });
          }
        }
      }
      setIsGettingLoggedIn(false);
    },
    // eslint-disable-next-line
    [navigate],
  );

  useEffect(() => {
    (async () => {
      if (token) {
        const dateNow = new Date().getTime();
        const authDate = Number(tokenExp ?? 0);

        if (dateNow > authDate) {
          logout();
        } else {
          try {
            setLoadingUserData(true);
            const userResponse = await API.get<UserType>("/get/user-profile");
            if ("error" in userResponse) {
              throw Error(userResponse.error);
            } else {
              setUser(userResponse.data);
              const user = userResponse.data;
              if (user.id && user.email)
                FHConnectUser(
                  user.id,
                  `${user.firstName} ${user.lastName}`,
                  user.email,
                );
            }
          } catch (e) {
            console.error(e);
          } finally {
            setLoadingUserData(false);
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, [logout, token]);

  useEffect(() => {
    (async () => {
      if (adminToken) {
        setLoadingUserData(true);
        const dateNow = new Date().getTime();
        const authDate = Number(adminTokenExp ?? 0);

        if (dateNow > authDate) {
          adminLogout();
          setLoadingUserData(false);
        } else {
          setLoadingUserData(true);

          const userResponse = await API.get<UserType>(
            "/admin-impersonate/get/get-admin-user/users",
          );
          if ("error" in userResponse) {
            adminLogout();
            setLoadingUserData(false);
            return userResponse.error;
          } else {
            if (currentAdminLoanId) {
              setUserAdmin(userResponse.data);
              setLoadingLoan(true);
              const result = await API.get<Loan>(
                `/admin-impersonate/get/loan/${currentAdminLoanId}`,
              );
              if ("error" in result) {
                return result.error;
              }
              setLoan(result?.data);
              setLoadingLoan(false);
              setLoadingUserData(false);
            } else {
              setLoadingUserData(false);
            }
          }
        }
      }
    })();
  }, [adminLogout, adminToken, adminTokenExp, currentAdminLoanId]);

  const getLoanData = async () => {
    const getMyLoanResponse = await API.get<Loan>("/get/my-loan");
    if ("error" in getMyLoanResponse) {
      alert(getMyLoanResponse.error);
    } else {
      if ("body" in getMyLoanResponse.data) {
        setInviteError(
          JSON.parse(getMyLoanResponse?.data?.body as unknown as string),
        );
        setModalInviteErrorIsShown(true);
        logout();
      } else {
        const loan = getMyLoanResponse?.data;
        const isBorrower = user?.id === loan?.borrowerId;
        const stepName = isBorrower ? "borrowerSteps" : "coborrowerSteps";
        const currentStep = loan?.[stepName]?.creditVerification?.adminError;
        if (
          loan?.loanStatusCode === "ARCHIVED" &&
          currentStep !== errorMessages.creditVerification.adminError
        ) {
          logout();
        }
        setLoan(loan);
        setLoadingLoan(false);
      }
    }
  };

  useEffect(() => {
    if (user) {
      setLoadingLoan(true);
      getLoanData();
    }
    // eslint-disable-next-line
  }, [user, location]);

  const adminLogin = useCallback(
    async (email: string, password: string) => {
      setIsGettingLoggedIn(true);
      const loginResponse = await API.post<LoginType>({
        url: "/admin-impersonate/login",
        data: {
          email: email.trim(),
          password,
        },
      });
      if ("error" in loginResponse) {
        setIsGettingLoggedIn(false);
        return loginResponse.error;
      }

      const authData = loginResponse?.data?.authData;
      if (authData) {
        setLoadingLoan(true);
        localStorage.setItem(
          LSAdminTokenExp,
          String(authData.idToken?.payload?.exp * 1000),
        );
        localStorage.setItem(LSAdminToken, authData.idToken.jwtToken);
        const result = await API.get<Loan>(
          `/admin-impersonate/get/loan/${currentAdminLoanId}`,
        );
        if ("error" in result) {
          setLoadingLoan(false);
          return result.error;
        } else {
          const loan = result?.data;
          setLoan(loan);
          setUserAdmin((prev) => ({
            ...prev,
            email: email,
            exp: authData.idToken.payload.exp * 1000,
            token: authData.idToken.jwtToken,
            id: authData.accessToken.payload.sub,
          }));
          setLoadingLoan(false);
        }
      }
    },

    [currentAdminLoanId],
  );

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        loan,
        loadingLoan,
        logout,
        setUser,
        loadingUserData,
        isGettingLoggedIn,
        inviteError,
        adminLogin,
        userAdmin,
        setCurrentAdminLoanId,
        getAdminLoan,
        openedDrawer,
        setOpenedDrawer,
      }}
    >
      <CDialog
        open={modalIsShown}
        title={"Loan Paid Off"}
        description={`Thank you for your application with ${privateLabel?.lenderName}. Loan ${loanId} is now Paid in full.`}
        icon="check"
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => setModalIsShown(!modalIsShown)}
          name="paidOffDialog-close"
        >
          Close
        </CButton>
      </CDialog>
      <CDialog
        open={modalInviteErrorShown}
        title={""}
        description={inviteError}
        icon="check"
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => {
            setModalInviteErrorIsShown(!modalInviteErrorShown);
            logout();
          }}
          name="paidOffDialog-close2"
        >
          Close
        </CButton>
      </CDialog>
      {children}
    </UserContext.Provider>
  );
};

export default useUser;
