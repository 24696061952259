import { Fragment } from "react";
import usePlaidFunding from "./usePlaidFunding";
import Typography from "@mui/material/Typography";
import CTrackerTimelineItem from "components/CTimelineItem";
import { useNavigate } from "react-router-dom";
import useTracker from "components/CTracker/useTrackerContext";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";

const FundingVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const plaid = usePlaidFunding();
  const { loan } = useTracker();
  const navigate = useNavigate();
  const borrowerSteps = loan?.borrowerSteps;
  const prevStep =
    borrowerSteps?.scheduleClosingVerification?.status === "success";
  const statusCheck =
    borrowerSteps?.disclosuresVerification?.status === "success" ||
    (borrowerSteps?.fundingVerification?.accountNumber && prevStep);
  const status =
    prevStep || statusCheck
      ? borrowerSteps?.fundingVerification?.status ?? "pending"
      : "pending";
  const message = borrowerSteps?.fundingVerification?.message ?? "";

  const fundsSent = borrowerSteps?.fundingVerification?.fundsSentAt;
  const isFunded = borrowerSteps?.fundingVerification?.status === "success";

  return (
    <Fragment>
      <CTrackerTimelineItem
        last
        status={status}
        title="Funding"
        tooltip="Please connect your bank details for funding. Funding will occur after the regulatory right of recision period."
        {...((status === "pending" || status === "failed") &&
          !fundsSent &&
          !isFunded &&
          statusCheck && {
            action: {
              message: "Select your bank account for funding",
              onClick: () => {
                plaid.load();
              },
            },
          })}
        {...(status === "success" && {
          action: {
            message: "Check your loan",
            onClick: () => navigate("/home-monitor", { replace: true }),
          },
        })}
        {...(plaid.isWaitingForMicroTransactions &&
          !loan?.borrowerSteps?.fundingVerification.routingNumber &&
          status === "analyzing" &&
          prevStep && {
            action: {
              message: "Verify your bank account",
              onClick: () => {
                plaid.load();
              },
            },
          })}
      >
        <Typography
          variant="h6"
          sx={{
            color: trackerStatusColor[status],
          }}
        >
          {status === "analyzing" && message}
          {status === "failed" && message}
          {status === "success" && message}
        </Typography>
      </CTrackerTimelineItem>
    </Fragment>
  );
};

export default FundingVerification;
